<template>
  <div class="page-6">
    <div class="title">
      <div class="title-header">疑问答疑</div>
      <span class="title-content">飞天云手机，安卓10，月卡28元，功能强大，内置多种免费强大的游戏工具，超长时间待机，打造属于您的游戏专机。</span>
    </div>

    <div class="main">
      <page-6-item v-for="item in page5ItemData" :title='item.title' :text='item.text'/>
    </div>
  </div>
</template>

<script>
  import Page6Item from './item/Page6Item.vue';
export default {
  name : "Page6",
  components : {
    Page6Item
  },
  data() {
    return {
      page5ItemData : [
        {title : '01.飞天云手机能干什么？' , text : '飞天云手机采用真机远程控制，为您提供长期挂载游戏、应用的手机云平台。'},
        {title : '02.飞天云手机贵吗？' , text : '飞天云手机采用多方合作的运营模式，将会是市场最实惠、最便宜的云手机平台。'},
        // {title : '03.WIFI连接是什么意思？' , text : 'WIFI连接的设备，表示该设备开启了iTunes无线同步的功能，可以用沙漏验机助手断开WIFI连接，也可以手动关闭WIFI断开连接!'},
      ]
    }
  },
}
</script>

<style scoped>
  .page-6{
    width: 100%;
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .title{
    width: 600px;
  }

  .title-header{
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 25px;
  }

  .title-content{
    font-size: 1rem;
    color: #444;
    line-height: 1.3rem;
    
  }
 
</style>