<template>
  <div class="page-6-item">
    <div class="title">{{this.title}}</div>
    <div class="text">{{this.text}}</div>
  </div>
</template>

<script>
  export default {
    name: "Page6Item",
    props:{
      title:{type : String, required : true},
      text:{type : String, required : true},
    }
    
  };
</script>

<style scoped>
  .page-6-item{
    width: 1000px;
    padding: 100px;

    border-bottom: 1px solid #aaa;
  }

  .title{
    font-size: 1.7rem;
    margin-bottom: 30px;
  }

  .text{
    font-size: 1rem;
    line-height: 1.5rem;
    color: #444;
    white-space: pre-wrap;   /*支持 data 中的 \n*/
  }

</style>